// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-compare-button-color: var(--primary-base-color);
    --product-compare-button-dark-color: var(--primary-dark-color);
    --product-compare-button-light-color: #{$white};
    --product-compare-button-grey-color: #d8d5d5;
    --product-compare-button-grey-light-color: rgba(243, 243, 243, .78);
}

.ProductCompareButton {
    --loader-scale: .6;
    --button-padding: 0;
    --button-color: var(--product-compare-button-color);
    --button-background: var(--product-compare-button-light-color);
    --button-border: var(--product-compare-button-color);
    --button-hover-color: var(--product-compare-button-dark-color);
    --button-hover-background: var(--product-compare-button-light-color);
    --button-hover-border: var(--product-compare-button-dark-color);

    margin-left: 1.08rem;
    vertical-align: middle;
    display: inline-block;

    @include mobile {
        margin-left: 1.26rem;
    }

    @include tablet-portrait {
        margin-top: 1.2rem;
    }

    &-Button {
        height: 47px;
        width: 47px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    @include desktop {
        &:hover {
            --button-color: var(--button-hover-color);
        }
    }

    &_isGrey {
        --button-color: var(--product-compare-button-grey-color);
        --button-background: var(--product-compare-button-grey-light-color);
        --button-border: var(--product-compare-button-grey-light-color);
        --button-hover-background: var(--product-compare-button-grey-light-color);
        --button-hover-border: var(--product-compare-button-color);

        @include desktop {
            &:hover {
                --button-color: var(--product-compare-button-color);
            }
        }
    }

    &_isActive {
        --button-color: var(--product-compare-button-color);

        @include desktop {
            &:hover {
                --button-color: var(--product-compare-button-dark-color);
            }
        }

        &:not(.ProductCompareButton_isGrey) {
            --button-color: var(--product-compare-button-light-color);
            --button-background: var(--product-compare-button-color);
            --button-border: var(--product-compare-button-color);
            --button-hover-background: var(--product-compare-button-dark-color);
            --button-hover-border: var(--product-compare-button-dark-color);

            @include desktop {
                &:hover {
                    --button-color: var(--product-compare-button-light-color);
                }
            }
        }
    }
}
