// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

.Logo {
    --placeholder-image: none;
    --placeholder-animation: none;
    --image-default-background: transparent;
    display: flex;
    align-items: center;
    height: 100%;

    svg {
        width: 100%;
        height: 100%;
    }

    .Image {
        &-Image {
            position: static;
            object-position: left;
        }
    }

    &-Placeholder {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
    }
}
