// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    --product-rating-filled-background: #{$black};
    --product-rating-background: #{$border};
    --product-rating-size: 16px;
}
