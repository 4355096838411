// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    --overlay-background: #{$white};
    --overlay-content-background: #{$white};
}

.Overlay {
    max-width: 100%;
    position: fixed;
    top: var(--header-height);
    left: 0;
    width: 100vw;
    height: calc(100% - var(--header-height));
    background-color: var(--overlay-background);
    overflow: auto;
    overflow-x: hidden;
    pointer-events: none;
    will-change: opacity;
    opacity: 0;

    // &_isStatic {

    // }

    &_isVisible {
        pointer-events: all;
        touch-action: pan-y;
        opacity: 1;
        z-index: z(overlay);
    }

    > * {
        background-color: var(--overlay-content-background);
    }
}
