// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

.ExtraMenu {
    &-Container {
        background-color: var(--menu-extra-bg);

        .Menu {
            &-Item {
                border-bottom: none;

                &_isActive {
                    background-color: transparent;

                    .Menu-Link {
                        font-weight: bold;
                    }
                }
            }

            &-SubCatLink::after,
            &-SubCatLink::before {
                display: none;
            }

            &-Link,
            &-ItemCaption {
                @include font(14, 18, bold);
                padding: 5px 0 0 0;
                text-transform: uppercase;
                cursor: pointer;
            }

            &-SubMenu {
                .Menu-Link {
                    @include font(16, 20, normal);
                    text-transform: none;

                    figcaption {
                        padding: 0;
                    }
                }
            }

            &-ItemList {
                border-top: none;

                &_type {
                    &_subcategory {
                        border: none;
                    }
                }
            }

            &-ItemCaption {
                padding: 5px 0 0 0;
            }

            &-SubItemWrapper {
                padding: 0 0 15px 0;

                .Menu-ItemCaption {
                    @include font (16, 20, normal);
                    text-transform: none;
                }

                &:first-of-type {
                    padding: 30px 0 15px 0;
                }

                &:last-of-type {
                    padding-bottom: 30px;
                }
            }
        }

        @include after-mobile {
            background-color: var(--menu-bg);

            .Menu {
                &-Item {
                    margin-right: 15px;

                    &:hover {
                        border-bottom: 1px solid currentColor;
                    }
                }

                &-Link {
                    &:hover,
                    &:focus {
                        .Menu-ItemCaption {
                            &::after {
                                bottom: -12px;
                            }
                        }
                    }

                    &_cookie {
                        padding: 0;
                    }
                }

                &-ItemCaption {
                    text-transform: uppercase;
                    @include font(12, 17, 400);
                }

                &-ItemFigure {
                    padding: 0 3px;
                }
            }
        }
    }

    &-ItemList {
        @include after-mobile {
            justify-content: flex-start;
            border-top: none;
            align-items: stretch;
        }
    }
}
