$font-opensans: 'Open Sans', sans-serif;
$font-belleza: 'Belleza', sans-serif;

@font-face {
    font-family: 'Open Sans';
    src:
        url('/style/fonts/OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('/style/fonts/OpenSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('/style/fonts/OpenSans-Light.woff2') format('woff2'),
        url('/style/fonts/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('/style/fonts/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
        url('/style/fonts/OpenSans-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('/style/fonts/OpenSans-Regular.woff2') format('woff2'),
        url('/style/fonts/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('/style/fonts/OpenSans-SemiBold.woff2') format('woff2'),
        url('/style/fonts/OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('/style/fonts/OpenSans-Italic.woff2') format('woff2'),
        url('/style/fonts/OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('/style/fonts/OpenSans-BoldItalic.woff2') format('woff2'),
        url('/style/fonts/OpenSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('/style/fonts/OpenSans-ExtraBold.woff2') format('woff2'),
        url('/style/fonts/OpenSans-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('/style/fonts/OpenSans-Bold.woff2') format('woff2'),
        url('/style/fonts/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('/style/fonts/OpenSans-LightItalic.woff2') format('woff2'),
        url('/style/fonts/OpenSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('/style/fonts/OpenSans-Medium.woff2') format('woff2'),
        url('/style/fonts/OpenSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('/style/fonts/OpenSans-MediumItalic.woff2') format('woff2'),
        url('/style/fonts/OpenSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('/style/fonts/OpenSans-Regular.woff2') format('woff2'),
        url('/style/fonts/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
