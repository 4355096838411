// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    --header-background-color: #{$background};
    --header-background-color-mobile: white;
    --header-wishlist-active-color: #{$red};
    --header-minicard-itemcount-bg: #{$black};
    --header-minicard-itemcount-color: #{$background};
    --uspblock-bg-color: #{$background};
    --header-usp-border-color: #{$background};
    --header-usp-border-width: 1px;
    --header-menuwrapper-height: 64px;
    --header-usp-height: 40px;
    --header-nav-height: 56px;
    --header-height: calc(var(--header-nav-height));
}

.Header {
    --header-top: calc(var(--offline-notice-height) + var(--demo-notice-height) + env(safe-area-inset-top));
    --header-top-promo: calc(var(--offline-notice-height) + var(--demo-notice-height) + var(--promotionbar-height) + env(safe-area-inset-top));

    @include after-mobile-any {
        --header-nav-height: 72px;
        --header-usp-height: 60px;
        --header-height: calc(var(--header-nav-height) + var(--header-menuwrapper-height));
        --header-top: calc(var(--offline-notice-height) + var(--demo-notice-height) + var(--promotionbar-height) + env(safe-area-inset-top));
    }

    height: calc(var(--header-height) + var(--header-top));

    &_isPromotion {
        animation: headerFadeInHeight .5s cubic-bezier(.645, .045, .355, 1) 5s forwards;
    }

    &-Wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        top: 0;
        left: 0;
        z-index: z(header);
        margin-top: 0;
        background-color: var(--header-background-color-mobile);
        position: relative;

        @include after-mobile {
            background-color: var(--header-background-color);
            margin-top: unset !important;
            position: fixed;

            &.showShadow {
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
            }
        }

        &_isPromotion {
            animation: headerFadeIn .5s cubic-bezier(.645, .045, .355, 1) 5s forwards;
        }
    }

    &_name {
        &_default,
        &_no-match,
        &_menu,
        &_menu_subcategory {
            /* stylelint-disable-next-line length-zero-no-unit */
            --header-usp-border-width: 0px;
        }

        &_default,
        &_home,
        &_menu,
        &_menu_subcategory,
        &_cart,
        &_wishlist,
        &_pdp,
        &_search {
            ~.Header-USP {
                display: none;
            }
        }
    }

    &-NavContainer {
        width: 100%;

        @include after-mobile {
            background-color: var(--header-background-color);
        }
    }

    &-Nav {
        display: grid;
        grid-template-columns: 20px minmax(150px, 1fr) repeat(3, 20px);
        grid-gap: 20px;
        align-content: center;
        width: 100%;
        height: var(--header-nav-height);
        padding: 0 var(--gutter);

        @include after-mobile {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            background-color: var(--header-background-color);
            margin: auto;
            max-width: calc(var(--container-max));
            padding-left: var(--gutter);
            padding-right: var(--gutter);
        }

        &_signedin {
            grid-template-columns: 20px minmax(150px, 1fr) repeat(4, 20px);
        }

        &-Border {
            @include after-mobile {
                border-bottom: 1px solid var(--menu-border-color);
                max-width: calc(var(--container-max) - var(--gutter) * 2);
                width: calc(100% - var(--gutter) * 2);
                margin: auto;
            }
        }

        .SearchField {
            &-Input,
            &-Placeholder {
                display: none;
            }

            @include after-mobile {
                margin-left: auto;
                width: auto;

                &-Input,
                &-Placeholder {
                    display: block;
                }
            }
        }

        ~.SearchField {
            @include after-mobile {
                display: none;
            }
        }
    }

    &-Switcher {
        display: none;
    }

    &-LogoWrapper {
        width: 95%;
        max-width: 190px;
        height: var(--header-total-height);
        position: relative;
        outline: none;

        @include after-mobile {
            min-width: 190px;
        }

        &_hidden {
            opacity: 0;
        }
    }

    &-MinicartButtonWrapper {
        position: relative;
    }

    &-Button {
        &_type {
            &_burgermenu {
                .svg-color {
                    fill: $black;
                }
            }

            &_back {
                display: none;
                text-align: left;

                svg {
                    position: relative;
                    top: -3px;
                    transform: scale(1.3);
                }

                .svg-color {
                    stroke: $black;
                }
            }
        }
    }

    &_isCheckout {
        --header-height: calc(var(--header-nav-height));

        @include after-mobile {
            --header-height: calc(var(--header-nav-height));
        }

        .Header {
            &-Nav {
                grid-template-columns: 25px 1fr;
            }

            &-Button,
            &-MinicartButtonWrapper {
                display: block;

                &:not(.Header-Button_type_back) {
                    display: none;
                }
            }
        }

        .ExtraMenu-Container {
            display: none;
        }

        ~.Header-USP {
            display: none;
        }
    }

    &_restoreCart {
        .Header-Nav {
            grid-template-columns: 1fr;
        }

        .Header-Button_type_back {
            display: none;
        }
    }

    &-WishlistButton {
        position: relative;
        display: none;

        @include after-mobile {
            display: block;
        }
    }

    .SearchField {
        overflow: hidden;

        @include after-mobile {
            overflow: unset;
        }

        &_isButton {
            padding: 0;
            align-items: center;

            .button {
                width: 100%;
                height: 100%;
            }

            .SearchField-Wrapper {
                display: flex;
                justify-content: center;

                @include after-mobile {
                    justify-content: flex-end;
                }
            }
        }
    }

    &-USP {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: var(--container-max);
        min-height: var(--header-usp-height);
        margin: 0 auto;
        border-top: var(--header-usp-border-width) solid var(--header-usp-border-color);

        .USPBlock-Picture {
            height: 40px;
            // padding: 0 var(--gutter);

            &:first-child {
                padding-left: var(--gutter);
            }

            @include after-mobile {
                iframe {
                    top: 12px;
                }
            }
        }

        @media (min-width: $mobile-sm) and (max-width: $desktop-sm) {
            background-color: var(--uspblock-bg-color);
        }

        @include after-mobile {
            padding: 0;
            border-top: none;

            .USPBlock {
                background-color: transparent;
                min-height: 60px;
            }

            >div {
                width: 100%;
                height: 100%;
                max-width: fit-content;
            }

            .trustpilot-widget {
                width: 100%;
                height: 100%;
            }

            iframe {
                top: 2px;
            }
        }

        .WidgetFactory-USPBlock,
        .ContentViewWidget-USPBlock {

            @include after-mobile {
                max-height: unset;
            }
        }
    }

    &-ItemsCount {
        position: absolute;
        top: calc(50% - 15px);
        right: 50%;
        transform: translate(150%, -50%);

        @include after-mobile {
            right: -13px;
            transform: translateY(-50%);
        }
    }

    &.popup_search {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 5;
    }

    &-MyAccount {
        position: relative;

        &-Hover {
            position: absolute;
            right: 0;
            padding-top: 5px;

            &-List {
                background-color: #fff;
                padding: 10px 20px;
            }

            &-Item {
                list-style-type: none;
                margin: 0;
                cursor: pointer;
            }
        }
    }
}

.MyAccountDropdown {
    --ring-color: #000;
    --ring-opacity: 0.05;
    display: flex;
    flex-direction: column;
    background: white;
    position: absolute;
    padding: 5px;
    right: 0;
    z-index: 5000;
    margin-top: 1rem;
    background-color: #fff;
    transform-origin: top right;
    width: 14rem;
    border-radius: 0.375rem;
    border-top-width: 1px;
    border-color: #f3f4f6;
    user-select: none;
    outline: none;
    box-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);

    .MyAccountDropdown-MenuItem {
        display: flex;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        align-items: center;
        width: 100%;
        border-radius: 0.375rem;
        text-decoration: none;

        &.active {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}

.MyAccountDropdownTransition-Enter {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: 100ms;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transform: scale(var(--transform-scale-x), var(--transform-scale-y));
}

.MyAccountDropdownTransition-EnterFrom {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
    opacity: 0;
}

.MyAccountDropdownTransition-EnterTo {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    opacity: 1;
}

.MyAccountDropdownTransition-Leave {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: 75ms;
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    transform: scale(var(--transform-scale-x), var(--transform-scale-y));
}

.MyAccountDropdownTransition-LeaveFrom {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    opacity: 1;
}

.MyAccountDropdownTransition-LeaveTo {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
    opacity: 0;
}

@keyframes headerFadeIn {
    0% {
        margin-top: 0;
        top: 0;
    }

    100% {
        top: var(--header-top-promo);
    }
}

@keyframes headerFadeInHeight {
    0% {
        height: calc(var(--header-height) + var(--header-top));
    }

    100% {
        height: calc(var(--header-height) + var(--header-top-promo));
    }
}
