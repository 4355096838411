// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

.CmsBlock-Wrapper {
    &.footer-social {
        @include before-desktop {
            p {
                max-width: 450px;
                margin: 0 auto;
            }

            p + p {
                display: none;
            }
        }
    }
}
