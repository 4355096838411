// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

.SocialFooterItems {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    margin-left: -3px;
    justify-content: center;
    @include after-mobile {
        justify-content: start;
    }

    &-Link {
        height: 20px;
        opacity: 1;

        &:hover {
            opacity: 0.5;
        }
    }

    &-Image .Image {
        height: 20px !important;
        margin-right: 20px !important;
        width: 20px !important;
    }
}
