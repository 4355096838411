// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    --select-background: #{$white};
    --select-option-background: #{$white};
    --select-option-hover-background: #{$background};
    --select-option-background2: #{$light-blue-bg};
}

.FieldSelect {
    cursor: pointer;
    width: 100%;
    border: 1px solid var(--input-text-border-color);
    border-radius: 0;
    outline: none;

    li {
        margin-left: 0;
        padding-left: 20px;
    }

    &:focus-visible {
        border: 1px solid var(--input-border-color);
    }

    &-Select {
        width: 100%;
        min-width: 100%;
        padding: 28px 12px 8px;
        background-color: var(--select-background);
        border: var(--input-text-border-color);
        outline: none;
        pointer-events: none;
    }

    &-Options {
        width: 100%;
        max-height: 0;
        top: 100%;
        position: absolute;
        left: 0;
        z-index: z(select);
        border-width: 0 1px 1px;
        border-style: solid;
        border-color: transparent;
        overflow-y: auto;
        transition: 200ms max-height;
        will-change: max-height;
        -webkit-overflow-scrolling: touch;

        &_isExpanded {
            max-height: 200px;
            width: 100%;
            border-bottom: 1px solid var(--input-text-border-color);
            border-left: 1px solid var(--input-text-border-color);
            border-right: 1px solid var(--input-text-border-color);
            border-radius: 0;
            outline: none;
        }
    }

    &-Option {
        padding: 10px 12px;
        outline: none;
        background: var(--select-option-background2);

        &:hover,
        &:focus {
            background: var(--select-option-hover-background);
        }
    }
}
