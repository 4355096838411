:root {
    --button-default-bg-color: #{$black};
    --button-default-color: #{$white};
    --button-hover-bg: #{$background};
    --button-hover-color: #{$black};
    --button-draw-color: #{$black};
    --button-cta-border-color: #{$border};
    --button-cta-active-bg: #{$border};
    --button-beige-bg-color: #f4f0ec;
}

.Button {
    @include font(16, 20, bold);
    display: block;
    min-width: 225px;
    position: relative;
    outline: none;
    padding: 15px 25px;
    background-color: var(--button-default-bg-color);
    border: 1px solid var(--button-default-bg-color);
    transition: all $rapid;
    color: var(--button-default-color);
    text-decoration: none;
    border-radius: 4px;

    svg g {
        stroke: var(--button-default-color);
    }

    &:hover,
    &:active {
        background-color: transparent;
        color: var(--button-hover-color);

        svg g {
            stroke: var(--button-hover-color);
        }
    }

    &_isSmall {
        min-width: auto;
        padding: 8px 20px;
    }

    &_isHollow {
        background-color: transparent;
        color: var(--button-default-bg-color);

        svg g {
            stroke: var(--button-default-bg-color);
        }

        &:hover,
        &:focus,
        &:active {
            background-color: var(--button-default-bg-color);
            color: var(--button-default-color);

            svg g {
                stroke: var(--button-default-color);
            }
        }
    }

    &_isBeige {
        @include button-beige();
    }

    &_white {
        background-color: unset;
        color: var(--button-default-bg-color);

        svg g {
            stroke: var(--button-default-bg-color);
        }

        &:hover,
        &:focus,
        &:active {
            background-color: var(--button-default-bg-color);
            color: var(--button-default-color);

            svg g {
                stroke: var(--button-default-color);
            }
        }
    }

    &_isHidden {
        display: none;
    }

    &[disabled] {
        pointer-events: none;
        opacity: .5;
    }

    &-Draw {
        position: relative;
        text-decoration: underline;
    }

    &-CTA {
        @include font(12, 20, bold);
        padding: 6px 15px;
        text-transform: uppercase;
        border: 1px solid var(--button-cta-border-color);

        svg g {
            stroke: var(--button-cta-border-color);
        }

        &:hover,
        &:focus,
        &:active {
            background-color: var(--button-cta-active-bg);
            color: var(--button-hover-color);

            svg g {
                stroke: var(--button-hover-color);
            }
        }
    }
}

button {
    &:disabled {
        opacity: 0.2;
    }
}
