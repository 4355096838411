// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    --input-bg: #{$white};
    --input-border-color: #{$black};
    --input-text-border-color: #f1f1f1;
    --checkmark-color: #{$black};
    --box-color: transparent;
    --input-text-label-color: #{$text-gray2};
    --error-color: #{$red};
}

.Field {
    &::before {
        content: '';
        width: 16px;
        height: 12px;
        position: absolute;
        right: 10px;
        top: 10px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12'%3E%3Cpath d='M14.785 1.108c-.138.424-.289.733-.452.928-1.066 1.278-2.194 2.26-2.737 2.951-.115.146.127.443 0 .605-.366.466-1.055.758-1.876 2.3-.29.544-1.007 1.402-2.151 2.574-.244.31-.616.503-1.02.53a1.438 1.438 0 01-1.09-.388c-.537-.797-1.025-1.34-1.464-1.632-.456-.302-.7-1.093-1.258-1.591C2.432 7.112 1.853 6.767 1 6.35' className='svg-color' stroke='%2324839F' strokeWidth='2' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round' /%3E%3C/svg%3E");
        opacity: 0;
        transition: opacity $fast;
        will-change: opacity;
    }

    label {
        @include font(14, 22);
    }

    label[for=vat_id] {
        font-size: clamp(9px, 1vw, 12px) !important;
    }

    &_revert {
        position: relative;
        margin: 0;

        display: flex;

        label {
            &:not(.Field-Label) {
                order: 0;
            }

            &.Field-Label {
                order: 1;

                ~ label {
                    margin-left: 0;
                }
            }
        }
    }

    &_type {
        &_text,
        &_tel,
        &_vat,
        &_textarea,
        &_select,
        &_password {
            position: relative;

            .Field-Label {
                pointer-events: none;
                position: absolute;
                left: 14px;
                top: 50%;
                color: var(--input-text-label-color);
                transition:
                    top $fast,
                    font-size $fast;
                will-change: top, font-size;
                transform: translateY(-50%);
            }

            &.Field_hasValue {
                .Field-Label {
                    @include font(12, 20);
                    top: 7px;
                    color: var(--input-text-label-color);
                    transform: unset;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 85%;
                }

                [type='text'],
                [type='tel'],
                textarea {
                    padding: 28px 12px 8px;
                }
            }
        }

        &_select {
            .Field-Label {
                @include font(12, 20);
                top: 7px;
                color: var(--input-text-label-color);
                transform: unset;
            }

            [type='text'],
            [type='tel'],
            textarea {
                padding: 28px 12px 8px;
            }
        }

        &_number {
            &::after,
            &::before {
                display: none;
            }

            button {
                width: 30px;
                height: 30px;
                border: 1px solid var(--input-text-label-color);
                transition:
                    border-color $rapid,
                    color $rapid,
                    background-color $rapid;
                will-change: border-color, color, background-color;

                &:hover,
                &:focus {
                    border-color: var(--input-border-color);
                    background-color: var(--input-border-color);
                    color: var(--input-bg);
                }

                &:disabled {
                    opacity: 1;
                }
            }
        }

        &_tel {
            .Field-Label {
                left: 14px;
                z-index: z(default);
                @include font(12, 20);
                top: 7px;
                color: var(--input-text-label-color);
                transform: unset;
            }
        }

        &_date {
            position: relative;

            .Field-Label {
                position: absolute;
                left: 12px;
                top: 17px;
                color: var(--input-text-label-color);
                transition:
                    top $fast,
                    font-size $fast;
                will-change: top, font-size;
            }
        }
    }

    &_type_textarea .Field-Label {
        left: 14px;
        top: 17px;
        transform: unset;
    }

    &-Subcontent {
        position: relative;
        margin-top: -20px;
        pointer-events: none;
        bottom: 17px;
        color: var(--input-text-label-color);
        will-change: top, font-size;
        font-size: 0.75rem;
        font-family: 'Open Sans', sans-serif;
        display: inherit;
        line-height: 1.25rem;
        background: rgba(255, 255, 255, 0.9);
        width: fit-content;
        margin-left: 1px;
        padding: 5px 5px 5px 12px;
    }

    &-Inputbox {
        position: relative;
        display: flex;
    }

    [type='date'],
    [type='text'],
    [type='tel'],
    [type='password'],
    textarea {
        width: 100%;
        padding: 18px 12px;
        border: 1px solid var(--input-text-border-color);
        border-radius: 0;
        outline: none;

        &:hover,
        &:focus {
            border-color: var(--input-border-color);
        }
    }

    textarea {
        resize: vertical;
    }

    &_isValid {
        &::before {
            opacity: 1;
        }
    }

    &_hasError input,
    &_hasError input[type][validation],
    &_hasError textarea,
    &_hasError textarea[validation] {
        border-color: var(--error-color);

        &:hover,
        &:focus {
            border-color: var(--error-color);
        }
    }

    [type='checkbox'],
    [type='radio'] {
        + .input-control {
            --box-color: transparent;

            width: 20px;
            height: 20px;
            margin-right: 12px;
            border-radius: 0;
        }

        &:checked {
            + .input-control {
                &::after {
                    --checkmark-color: #{$black};

                    background-color: var(--checkmark-color);
                    border-color: var(--checkmark-color);
                }
            }
        }
    }

    [type='checkbox'] {
        + .input-control {
            &::after {
                top: 50%;
                left: 50%;
                width: 12px;
                height: 12px;
                box-shadow: none;
                transform: translate(-50%, -50%);
            }
        }

        &:focus-visible {
            ~label {
                outline: -webkit-focus-ring-color auto 1px;
                outline-offset: 5px;
            }
        }
    }

    [type='radio'] {
        + .input-control {
            border-radius: 0%;

            &::after {
                width: 12px;
                height: 12px;
                border-radius: 0;
            }
        }
    }

    [type='number'] {
        width: 30px;
        height: 30px;
        outline: none;
        background-color: transparent;
        border: none;
        border-top: 1px solid var(--input-text-label-color);
        border-bottom: 1px solid var(--input-text-label-color);
        text-align: center;
    }

    [type='date'] {
        text-align: right;
    }

    &-Message {
        @include font(10, 18, normal, var(--error-color));
        padding: 5px 12px;
    }
}
