// Placeholders
:root {
    --placeholder-gradient-color: #{$black};
    --placeholder-image: linear-gradient(to right, #{$border} 0, #{$border2} 15%, #{$border} 30%);
    --placeholder-size: 1200px 100%;
    --placeholder-animation: placeholderanimation 2s linear infinite;
}

@keyframes appear {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes rotate {
    from { transform: rotate(0); }
    to { transform: rotate(360deg); }
}

@keyframes Dots {
    0% { content: ''; }
    33% { content: '.'; }
    66% { content: '..'; }
    100% { content: '...'; }
}

@keyframes fadeIn {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(-100%);
        opacity: 0;
    }
}

@keyframes display {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes placeholderanimation {
    from { background-position: -1200px 0; }
    to { background-position: 1200px 0; }
}
