// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    --trustpilot-bg: #{$white};
}

.TrustpilotBlock {
    max-width: calc(var(--container-max) - calc(var(--gutter) * 2));
    margin: 0 auto;
    padding: 30px var(--gutter);
    background-color: var(--trustpilot-bg);

    @include after-mobile {
        margin-top: 70px;
    }
}
