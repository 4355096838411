// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    --productlink-breadcrumb-bg: #{$border};
    --productlink-breadcrumb-active-bg: #{$black};
    --productlink-width: 1200px;
}

.ProductLinks {
    @include after-mobile {
        grid-column: 1 / 3;
        width: 100%;
        margin: 0 auto;
    }

    .ContentWrapper {
        @include after-mobile {
            padding: 0;
        }
    }

    .ProductCard-ContentWrapper {
        @include after-mobile {
            margin: 50px 0 55px 0;
        }
    }

    &-Wrapper {
        margin-bottom: 30px;

        @include before-desktop {
            padding: 0;
        }

        &_showAsGrid {
            padding: 0 var(--gutter-sm);
        }

        &_isCart {
            padding: 0;
        }
    }

    &-Title {
        font-family: 'Open Sans', sans-serif;
        text-align: center;
        @include font(25px, 25px, bold);
        @extend .TitleFont;
        text-transform: uppercase;
        margin: 70px 0 20px 0;

        @include after-mobile {
            font-size: 36px;
            line-height: 3.125rem;
            font-weight: 700;
            margin-bottom: 40px;
            margin-top: 50px;
        }

        &_isCart {

            @include font(28, 36, 700, $text);
            margin: 20px 0;

            @include after-mobile {
                text-align: left;
            }
        }

        &_shopTheLook {
            font-family: 'Open Sans', sans-serif;
            @include font(25, 25, bold);
            margin-bottom: 2rem;

            @include after-mobile {
                @include font(50, 50, bold);
            }
        }
    }

    &-List-Wrapper {
        width: 100%;
        overflow-x: auto;
        padding: 0  0 0 37px;

        -ms-overflow-style: none;
        scrollbar-width: none;

        @include after-mobile {
            padding: 0;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        &_isCart {
            padding: 0 var(--gutter-sm);

            @include after-mobile {
                margin-bottom: 30px;
                background-color: var(--cartpage-bg);
                border-radius: 4px;
            }
        }
    }

    &-List {
        display: flex;
        grid-gap: 10px;

        @include after-mobile {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 12px;
            margin: 0;
        }

        .ProductCard {
            width: 100%;
            max-width: 200px;

            @include after-mobile {
                max-width: none;
            }

            &:last-child {
                margin-right: 70px;
            }
        }

        &_showAsGrid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: var(--gutter-sm);
            margin: 0;

            @include after-mobile {
                grid-template-columns: repeat(4, 1fr);
            }
        }

        &_isCart {
            display: block;
            width: 100%;
            margin: 0;
        }

        &-Item {
            &-Name {
                @include font(14, 14, 100);
            }

            &-Price {
                @include font(14, 14, bold);
            }
        }
    }

    &-Breadcrumbs {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 30px;
    }

    &-Breadcrumb {
        width: 7px;
        height: 7px;
        margin-right: 8px;
        border-radius: 100%;
        background-color: var(--productlink-breadcrumb-bg);
        text-indent: -9999em;

        &:last-child {
            margin-right: 0;
        }

        &_isActive {
            background-color: var(--productlink-breadcrumb-active-bg);
        }
    }

    &-ShopTheLook-Wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem;
        margin-bottom: 50px;

        @include after-mobile {
            justify-content: center;
            width: 100%;
            grid-template-columns: 2fr minmax(var(--max-width-aside), 1fr);
            grid-gap: 2rem;
        }

        .ProductLinks {
            &-ShopTheLook-Image-Wrapper {
                .Image {
                    position: relative;
                }
            }

            &-ShopTheLook-List-Wrapper {
                align-self: flex-end;

                .ProductLinks-List {
                    list-style-type: none;
                    display: block;

                    li {
                        margin: 10px 0;

                        a {
                            text-decoration: none;
                            border-bottom: 1px solid #d3d3d3;

                            .ProductLinks-List-Item-Price {
                                font-weight: bold;
                            }
                        }
                    }

                    .ProductLinks-Add-All-To-Cart {
                        text-align: center;
                        max-height: 50px;
                        height: 50px;
                        border: 1px solid #0d1821;
                        margin-top: 40px;
                        margin-bottom: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-decoration: none;
                        font-weight: bold;
                        width: 100%;
                        position: relative;

                        span.adding {
                            position: absolute;
                        }

                        @include after-mobile {
                            width: 75%;
                        }
                    }
                }
            }
        }
    }
}
