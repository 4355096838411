// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

.WidgetFactory {
    &-SliderPlaceholder {
        animation: var(--placeholder-animation);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        padding-bottom: 42%;
        margin: 0;

        @include mobile {
            min-height: 226px;
        }
    }
}
