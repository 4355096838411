// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    --search-field-bg: rgba(255, 255, 255, 0.5);
}

.SearchField {
    display: flex;
    align-items: flex-start;
    width: 100%;
    cursor: pointer;

    &-Wrapper {
        position: relative;
        width: 100%;
        pointer-events: none;
    }

    &-Input {
        border-width: 1px;
        border: none;
        border-bottom: 1px solid;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        @include font(20, 44);

        width: 100%;
        padding: 0 40px;

        color: transparent;

        @include after-mobile {
            border-width: 1px;
            border-radius: 4px;
            @include font(14, 42);
            background-color: var(--search-field-bg);
            // border: 1px solid #cdcdcd;
            border: none;
        }

        &:hover,
        &:focus {
            outline: none;
        }

        &:focus {
            ~ .SearchField-Placeholder {
                color: transparent;
            }
        }
    }

    &-SearchIcon,
    &-CloseIcon {
        display: flex;
        align-items: center;
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
    }

    &-Placeholder {
        @include font(20, 44);
        display: flex;
        align-items: center;
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
        visibility: hidden;

        @include after-mobile {
            @include font(14, 42);
        }

        &_isPlaceholderVisible {
            visibility: visible;
        }

        svg {
            opacity: 0.5;
        }

        span {
            opacity: 0.4;
            margin-left: 12px;
        }
    }
}
