// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    /* stylelint-disable-next-line length-zero-no-unit */
    --offline-notice-height: 0px;
    --offline-notice-display: none;
    --offline-notice-color: #{$yellow};
    --offline-notice-top:
        calc(
            var(--demo-notice-height)
            + var(--promotionbar-height)
            + env(safe-area-inset-top)
        );
}

.OfflineNotice {
    display: var(--offline-notice-display);
    height: var(--offline-notice-height);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: var(--offline-notice-top);
    bottom: 0;
    right: 0;
    left: 0;
    background: var(--offline-notice-color);
    z-index: z(offline);

    @at-root .offline {
        --placeholder-image: linear-gradient(var(--placeholder-gradient-color), var(--placeholder-gradient-color));
        --placeholder-animation: none;
        --offline-notice-height: 40px;
        --offline-notice-display: flex;
    }
}
