// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    --notification-color: #{$white};
    --notification-background: #{$black};
    --notification-color-error: #{$white};
    --notification-background-error: #{$red};
    --notification-color-sucess: #{$white};
    --notification-background-sucess: #{$black};
}

.Notification {
    --animation-duration: 0; /* to sync CSS animation and JS callback */

    display: flex;
    justify-content: space-between;
    position: relative;
    padding: var(--gutter);
    color: var(--notification-color);
    background: var(--notification-background);
    will-change: max-height, opacity, transform, padding, margin;

    &-Text {
        margin-top: 0;
        font-weight: bold;
        margin-right: 40px;
    }

    &-Debug {
        white-space: pre-wrap;
        background: white;
        color: black;
        padding: 5px;
        max-height: 150px;
        overflow: auto;
    }

    &-Button {
        .svg-color {
            stroke: var(--notification-color);
        }
    }

    &_is {
        &_opening {
            animation: fadeIn var(--animation-duration) cubic-bezier(.645, .045, .355, 1) forwards;
        }

        &_closing {
            animation: fadeOut var(--animation-duration) cubic-bezier(.645, .045, .355, 1) forwards;
        }
    }

    &_type {
        &_error {
            --notification-color: var(--notification-color-error);
            --notification-background: var(--notification-background-error);
        }

        &_success {
            --notification-color: var(--notification-color-sucess);
            --notification-background: var(--notification-background-sucess);
        }
    }
}
