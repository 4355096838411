// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

.AddToCart {
    min-width: auto;
    font-weight: bold;

    &:hover,
    &:focus {
        background-color: var(--button-hover-bg);
        color: var(--button-hover-color);

        svg g {
            stroke: var(--button-hover-color);
        }
    }

    span {
        display: inline-block;
        opacity: 1;
        transform: translateY(0);
        transition-property: transform, opacity;
        will-change: transform, opacity;
        transition-timing-function: ease-in;
        transition-duration: .25s;
        font-weight: bold;

        &:hover,
        &:focus {
            color: var(--button-hover-color) !important;
        }

        &:last-child {
            width: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            transform: translateY(0);
        }
    }

    .beforeElemContainer {
        margin-right: 10px;
        display: inline-block;
        transform: translate(0);
        will-change: transform, opacity;
        transition-timing-function: ease-in;
        transition-duration: .25s;
        opacity: 1;
    }

    &_isPlaceholder {
        height: 90px;
        display: inline-block;
        padding: var(--button-padding);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        will-change: background-position;
    }

    &_isLoading {
        .beforeElemContainer {
            opacity: 0;
        }

        span {
            &:first-of-type {
                opacity: 0;
                transform: translateY(-50%);
            }

            &:last-child {
                opacity: 1;
                transform: translateY(0%);
            }
        }

        &:active {
            span {
                &:last-child {
                    opacity: .9;
                    transform: translateY(-120%);
                }
            }
        }
    }
}
