// settings
@import 'settings/color';
@import 'tools/mixins';
@import 'settings/variables';

// tools
@import 'tools/breakpoints';

@import 'utilities/deletethis';

@import './base/font';

// default variables
@import 'abstract/abstract';

// generic
@import 'generic/normalize';
@import 'generic/reset';
@import 'generic/base';
@import 'generic/typography';

// objects
@import 'objects/animations';
@import 'objects/grid';
@import 'objects/buttons';
@import 'objects/chat';

// utilities
@import 'utilities/utilities';

#Adyen3dsRedirect {
    display: none;
    background: #fff;

    &.mounted {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        width: 100vw;
        z-index: 10000000;
    }
}
