// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

.Breadcrumb {
    @include font(12, 17);
    display: inline-flex;
    margin-left: 0;

    * {
        outline: none;
    }

    &:not(:last-of-type)::after {
        content: '>';
        margin: 0 5px;
    }

    &-Link {
        text-decoration: none;
        transition: opacity ease-in-out 0.1s;

        &:hover {
            opacity: 0.6;
        }
    }
}
