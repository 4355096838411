// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    --popup-background: #0003;
    --popup-header-border-color: #{$border2};
    --popup-content-background: #{$white};
    --popup-min-width: 100%;
    --popup-max-width: 1440px;
}

.Popup {
    position: fixed;
    left: 0;
    top: 0;
    background-color: var(--popup-background);
    opacity: 0;
    pointer-events: none;
    z-index: z(popup);

    &_isVisible {
        width: 100vw;
        height: 100%;
        pointer-events: all;
        opacity: 1;
    }

    &-Header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        padding-bottom: 24px;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            top: 65px;
            background-color: var(--popup-header-border-color);

            @include after-mobile {
                display: none;
            }
        }

        &_isSticky {
            justify-content: unset;
        }
    }

    &-Heading {
        margin-bottom: 0;
        text-transform: uppercase;
        user-select: none;

        @include mobile {
            @include font(14, 22, bold, false, 1.2);
        }

        @include after-mobile {
            width: 80%;
            position: relative;
            top: 30px;
            margin: 0 auto;
            text-align: center;
        }
    }

    &-CloseBtn {
        position: absolute;
        right: 15px;
        top: 15px;
    }

    &-Content {
        min-width: var(--popup-min-width);
        max-width: var(--popup-max-width);
        height: 100%;
        background-color: var(--popup-content-background);
        padding: var(--gutter);
        overflow-y: auto;

        @include after-mobile {
            --popup-min-width: 600px;
            height: auto;
            max-height: 80%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &_small {
            .Popup-Header {
                position: sticky;
                display: flex;
                flex-direction: row;
                padding: 20px;
                align-items: center;
                z-index: 10;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 50px;
                    bottom: 0;
                    top: 90%;
                    left: 0;
                    background: -webkit-linear-gradient(white, transparent) bottom repeat;
                    background: linear-gradient(white, transparent) bottom repeat;
                    z-index: 10;
                }

                @include after-mobile {
                    padding-top: var(--gutter);

                    &::before {
                        height: 80px;
                    }
                }

                .Popup-Heading {
                    @include after-mobile {
                        top: 15px;
                        padding-bottom: 20px;
                        padding-left: 20px;
                    }
                }
            }

            .Popup-Header::after {
                display: none;
            }

            .Popup-CloseBtn {
                position: relative;
                top: 0;
                right: 0;
            }
        }
    }

    .Loader {
        position: fixed;

        &_isBlock {
            position: static;
        }
    }
}
