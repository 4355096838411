// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    --productprice-color-normal-discount: #{$text-gray};
    --productprice-color-discount: #{$text-gray};
}

.ProductPrice {
    &-HighPrice {
        display: none;
        color: var(--productprice-color-normal-discount);
        font-weight: 600;

        &_isVisible {
            display: block;
        }

        ~ span data {
            font-weight: bold;
        }
    }

    ins {
        text-decoration: none;
        font-weight: bold;
    }

    &-QtyIncrementPrice {
        font-weight: normal;
        font-size: 18px;
    }

    &-QtyIncrementWarning {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
    }

    &-Each {
        font-size: 14px;

        &_isOldPrice {
            color: var(--productprice-color-normal-discount);
        }
    }

    &-OuterWrapper {
        display: flex;
        flex-wrap: wrap;
        column-gap: 15px;
        margin-bottom: 4px;

        &:has(.ProductPrice-Each) {
            margin-bottom: 2px;
        }
    }

    &-InnerWrapper {
        display: none;

        &_isVisible {
            display: flex;
            gap: 2px;
            align-items: baseline;
        }
    }
}
