// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    --store-switcher-bg: #{$white};
    --store-switcher-list-background: #{$white};
    --store-switcher-border-color: #{$light-gray};
}

.StoreSwitcher {
    @include font(14, 18, normal, false, 0.5);
    margin: 15px 0;
    position: relative;
    background-color: var(--store-switcher-bg);
    border-bottom: 1px solid var(--menu-border-color);

    &-Popup {
        .Popup-Content {
            --popup-min-width: 400px;
        }
    }

    svg {
        position: absolute;
        right: 0;
    }

    @include after-mobile {
        svg {
            position: relative;
        }
    }

    &-Title,
    &-Button {
        display: flex;
        align-items: center;

        .Image {
            width: 16px;
            height: 16px;
            margin-right: 10px;
            position: relative;
        }

        svg {
            margin-left: 10px;
        }
    }

    &-StoreList {
        display: inline;

        @include after-mobile {
            text-align: center;
        }
    }

    &-Button {
        @include font(14, 18, normal, false, 0.5);
        width: 100%;
        padding: 0 var(--gutter);
        text-align: left;
    }

    &-SubMenu {
        position: absolute;
        padding: 0 var(--gutter);
        background-color: var(--menu-sub-bg);
        opacity: 0;
        visibility: hidden;
        transform: scaleY(0);
        transform-origin: top;
        transition: transform $shoot ease-in-out;

        &_isOpen {
            position: static;
            opacity: 1;
            visibility: visible;
            transform: scaleY(1);
        }
    }

    &-Link {
        @include font(14, 50, normal, false, 0.5);
        display: block;
        width: 100%;
        text-align: left;
    }

    &-Header {
        .Image {
            margin-right: 0;
        }
    }
}
