// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

.Image {
    &-Content {
        @include font(12, 20, normal, false, 0);
    }

    &-Image {
        &_isBackGround {
            background-size: contain;
        }
    }

    &-Zoom {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-position: 50% 50%;
        object-fit: contain;

        &::after {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--image-default-background);
        }

        > div {
            height: 100%;
            width: 100%;
        }

        .iiz__img {
            height: 100%;
            width: 100%;
            object-position: 50% 50%;
            object-fit: contain;
        }
    }
}
