// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    --active-icon-color: #{$black};
}

.ProductWishlistButton {
    &-Button {
        span {
            display: none;
        }

        &_isInWishlist {
            .svg-color {
                fill: var(--active-icon-color);
                stroke: var(--active-icon-color);
            }
        }
    }
}
