// Salesforce Chat overrides

.embeddedServiceHelpButton {
    top: unset;
    bottom: 0;
    left: 24px !important;
    right: unset !important;
}

.embeddedServiceHelpButton .helpButton .uiButton.helpButtonDisabled { display: none; }

.embeddedServiceHelpButton .helpButton .uiButton {
    background-color: #f0abb0;
    font-family: 'Arial', sans-serif;
}

.embeddedServiceHelpButton .helpButton .uiButton:focus {
    outline: 1px solid #795759;
}

.embeddedServiceHelpButton .helpButton {
    left: 24px !important;
    right: unset !important;
}

.embeddedServiceSidebar.layout-docked .dockableContainer {
    left: 24px !important;
    right: unset !important;
}

.embeddedServiceSidebar .minimizedContainer {
    left: 24px !important;
    right: unset !important;

    .messageContent {
        justify-content: center !important;
    }

    @include mobile {
        min-width: 64px !important;
        min-height: 64px !important;
        height: 64px !important;
        width: 64px !important;
        border-radius: 100% !important;

        .minimizedText {
            display: none;
        }

        .embeddedServiceIcon.x-small svg {
            width: 1.75rem;
            height: 1.75rem;
        }
    }
}

@media (max-width: 720px) {
    .helpButton {
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 32px !important;
    }

    .helpButton .uiButton {
        min-width: 64px !important;
        min-height: 64px !important;
        padding: 0 !important;
        border-radius: 999px !important;
    }

    .helpButton .uiButton:focus,
    .helpButton .uiButton:hover,
    .helpButton .uiButton:active {
        outline: none !important;
    }

    .helpButton .uiButton::before {
        border-radius: 999px !important;
    }

    .helpButton .embeddedServiceIcon {
        margin: auto !important;
        display: block !important;
    }

    .helpButton .embeddedServiceIcon::before {
        font-size: 1.75rem !important;
    }

    .helpButton .helpButtonLabel {
        display: none !important;
    }
}
