// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    --promotionbar-height: 40px;
    --promotionbar-top:
        calc(
            var(--demo-notice-height)
            + env(safe-area-inset-top)
        );
}

.PromotionBar {
    display: flex;
    transition: margin-left $normal;
    will-change: margin-left;

    &-Wrapper {
        transform: translateY(-100%);
        animation: fadeIn .5s cubic-bezier(.645, .045, .355, 1) 5s forwards;
        width: 100vw;
        height: var(--promotionbar-height);
        position: fixed;
        left: 0;
        top: var(--promotionbar-top);
        z-index: z(header);
        overflow: hidden;

        /* @include mobile {
            position: relative;
            animation: fadeIn .3s cubic-bezier(.645, .045, .355, 1) 0.3s forwards;
        } */

        @at-root .promotionBarIsHidden {
            /* stylelint-disable-next-line length-zero-no-unit */
            --promotionbar-height: 0px;
        }
    }

    &-Close {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        z-index: z(default);
    }

    &-Item {
        font-size: rem(12);
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: var(--promotionbar-height);
        padding: 0 40px 0 15px;
        text-align: center;
        background-color: rgb(230, 166, 181);
        text-transform: uppercase;
        letter-spacing: 0.5px;

        a {
            padding: 0 5px;
        }

        @include mobile-sm {
            @include font(10, 12);
        }
    }
}
