
html,
body {
    min-height: 101%;
}

body {
    height: 100%;
    min-height: -webkit-fill-available;
    position: relative;
    background-color: $white;
}

html {
    height: -webkit-fill-available;
}

html,
#root {
    background-color: $background;
}

.HOMEPAGE {
    background-color: $background;
}

#Coi-Renew {
    display: none !important;
}

table:not(.coitable__table) {
    width: 100%;

    tr {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        position: relative;
        border-bottom: 1px solid $black;
    }

    th,
    td {
        width: 100%;
        flex-grow: 1;
        padding: 5px;
        line-height: 36px;
    }
}

.scrollDisabled {
    position: fixed;
    margin-top: 0;
    width: 100%;
    overflow-y: scroll;
}

/* Sofacompany Generic Styles */
.flex-container {
    display: flex;
}

.container-horizontal-centered {
    display: flex;
    justify-content: center;
}

.flex-item {
    width: 100%;
}

.cursor-default {
    cursor: default;
}

p,
button {
    color: currentColor;
}

.text-danger {
    color: $red;
}

.checkmark {
    margin-left: 20px;
    position: relative;

    &::before {
        content: '';
        width: 9px;
        height: 9px;
        position: absolute;
        left: -20px;
        display: flex;
        top: 6px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12'%3E%3Cpath d='M14.785 1.108c-.138.424-.289.733-.452.928-1.066 1.278-2.194 2.26-2.737 2.951-.115.146.127.443 0 .605-.366.466-1.055.758-1.876 2.3-.29.544-1.007 1.402-2.151 2.574-.244.31-.616.503-1.02.53a1.438 1.438 0 01-1.09-.388c-.537-.797-1.025-1.34-1.464-1.632-.456-.302-.7-1.093-1.258-1.591C2.432 7.112 1.853 6.767 1 6.35' className='svg-color' stroke='black' strokeWidth='2' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round' /%3E%3C/svg%3E");
    }
}

.react-hint {
    padding: 5px;
    position: absolute;
    z-index: 9999;
    cursor: default;
    animation: .5s fadeInReactHint;
}

.react-hint__content {
    padding: 5px 10px;
    border-radius: 5px;
    background: #000;
    color: #fff;
    @include font(14, 18, regular);
}

.react-hint::after {
    content: '';
    width: 0;
    height: 0;
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 5px solid transparent;
}

.react-hint--top::after {
    top: auto;
    border-bottom: none;
    border-top-color: #000;
}

.react-hint--left::after {
    left: auto;
    border-right: none;
    border-left-color: #000;
}

.react-hint--right::after {
    right: auto;
    border-left: none;
    border-right-color: #000;
}

.react-hint--bottom::after {
    bottom: auto;
    border-top: none;
    border-bottom-color: #000;
}

@keyframes fadeInReactHint {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.hideDesktop {
    @include after-mobile {
        display: none;
    }
}

.hideMobile {
    @include before-desktop {
        display: none;
    }
}

.MyAccountTabList-ExpandableContentButton {
    display: block !important;
}
