// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

.AttentionBox {
    padding: 10px;
    background-color: $yellow_light;
    font-size: 12px;
    width: 100%;
    position: relative;
    margin-top: 5px;
    border: 1px solid var(--input-text-border-color);
}
