// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    --menu-bg: #{$background};
    --menu-bg-mobile: white;
    --menu-extra-bg: #{$background};
    --menu-border-color: #{$border3};
    --menu-sub-bg: white;
    --menu-sub2-bg: white;
    --menu-plus-bg: #{$text};
    --menu-link-color: #151e26;
    --subcategory-height: 250px;

    @include after-mobile {
        --menu-sub2-bg: #{$white};
        --menu-sub-bg: #{$white};
    }
}

.Header-Wrapper.HideMenu {
    .Menu {
        &-MenuWrapper {
            display: none;
        }
    }
}

.Menu {
    &-MenuWrapper {
        display: flex;
        flex-direction: column;

        @include after-mobile {
            display: block;
            width: 100%;
            background: var(--menu-bg);
            padding: 0;
            height: var(--header-menuwrapper-height);
        }
    }

    &-WishlistButton {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0 var(--gutter);
        outline: none;

        p {
            font-size: calc(0.75rem);
            line-height: calc(3.125rem);
            font-weight: 400;
            letter-spacing: 0.5px;
            display: flex;
            justify-content: space-between;
            align-content: center;
            text-transform: uppercase;
            margin-right: 10px;
        }
    }

    &-ItemList {
        @include after-mobile {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            gap: var(--gutter);
            flex-wrap: nowrap;
        }

        [role='button'] {
            outline: none;
        }

        &_type {
            &_main {
                @include after-mobile {
                    .Menu-Picture {
                        display: none;
                    }
                }
            }

            &_subcategory {
                @include after-mobile {
                    display: grid;
                    grid-template-columns: repeat(4, minmax(200px, 1fr));
                    gap: calc(var(--gutter) * 2);
                    align-items: baseline;
                    max-height: var(--subcategory-height);

                    .Menu {
                        &-ItemFigure {
                            padding: 0 0 var(--gutter);
                        }

                        &-ItemCaption {
                            text-transform: none;
                            @include font(18, 24, bold);
                        }
                    }
                }
            }
        }

        &_firstSub {
            display: block;
            position: relative;
            padding: 0;

            @include after-mobile {
                padding: 0 0 15px 0;
            }

            .Menu-ItemCaption {
                padding: 0;
            }
        }
    }

    &-SubItemWrapper {
        position: relative;
        padding: 22px 0 15px 0;

        &_backgroundColor {
            height: calc(100% + 2px);
            position: absolute;
            top: 0;
            width: 100vw;
            left: calc(-2 * var(--gutter));
        }

        &_isSubTitle {
            padding: 0 0 15px 10px;

            @include after-mobile {
                padding: 0 0 15px 0;

                .Menu-ItemCaption {
                    @include font(16,20, normal);
                    font-size: 16px !important;
                }
            }

            .Menu-ItemCaption {
                @include font(14, 22, normal);
                font-size: 14px;
            }

            .Menu-ItemFigure {
                padding: 0 !important;
            }
        }

        &_isSubTitle:first-of-type {
            padding: 22px 0 15px 10px;
            @include after-mobile {
                padding: 0 0 15px 0;
            }
        }

        &_isSubTitle:last-of-type {
            padding: 0 0 22px 10px;
            @include after-mobile {
                padding: 0 0 15px 0;
            }
        }

        .Menu-Link {
            @include font(14, 18, bold);

            @include after-mobile {
                @include font(18, 20, bold);
            }
        }

        .Menu-ItemCaption {
            padding: 0;
        }

        @include after-mobile {
            background-color: transparent !important;
            padding: 0 0 15px 0;

            &_backgroundColor {
                display: none;
            }
        }
    }

    &-Item {
        @extend .DisableListType;
        margin-left: calc(var(--gutter) * 2);
        margin-right: calc(var(--gutter) * 2);

        border-bottom: 1px solid var(--menu-border-color);

        @include after-mobile {
            display: flex;
            align-items: flex-start;
            margin: 0;
        }

        &_wishlist {
            background-color: var(--menu-extra-bg);
        }

        &_pipe {
            height: 20px;
            width: 1px;
            background: black;
        }

        &:last-of-type {
            border-bottom: none;
        }

        .Menu-Divider {
            display: none;

            @include after-mobile {
                display: block;
                width: 1px;
                min-height: 22px;
                background-color: #b2b0ad;
                margin: 0 0 0 var(--gutter);
            }
        }
    }

    &-Item,
    &-SubCatLink {
        position: relative;
        outline: none;

        @include after-mobile {
            border: none;
        }
    }

    &-SubCatLink {
        svg {
            position: absolute;
            top: calc(50% - 6px);
            right: 1px;
        }

        &-Item {
            position: relative;
        }

        &_isVisible svg {
            transform: rotate(270deg) !important;
        }
    }

    &-Link {
        text-decoration: none;
        font-size: 16px;

        @include after-mobile {
            font-size: 18px;
        }

        .Menu-ItemCaption,
        .Menu-ItemFigure {
            position: relative;

            @include after-mobile {
                will-change: border-bottom;
                transition: border-bottom $rapid;
                border-bottom: 1px solid transparent;
            }

            &_type_main {
                font-size: 16px;
            }
        }

        &:hover,
        &:focus {
            outline: none;

            .Menu-ItemCaption {
                position: relative;

                @include after-mobile {
                    border-bottom: 1px solid currentColor;
                }
            }
        }
    }

    &-ItemFigure {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        outline: none;
        position: relative;
        z-index: 1;

        @include after-mobile {
            padding: 0;
        }

        &_type {
            &_subcategory {
                position: relative;

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    background-color: var(--menu-plus-bg);
                }

                &::before {
                    width: 11px;
                    height: 1px;
                    right: 20px;
                    top: 25px;
                }

                &::after {
                    width: 1px;
                    height: 11px;
                    right: 25px;
                    top: 20px;
                }
            }
        }
    }

    &-Picture {
        display: flex;
        align-items: center;
        position: relative;
        width: 25px;
        margin-right: 16px;
    }

    &-Image {
        padding-bottom: 60%;
    }

    &-ItemCaption {
        @include font(16, 18, normal, false, 0.5);
        padding: 16px 0;
        display: flex;
        justify-content: space-between;
        align-content: center;

        @include after-mobile {
            @include font(14, 22, normal);
        }
    }

    &-ExtraCategories {
        padding: 30px 0 70px;

        .StoreSwitcher {
            background-color: transparent;
            border-bottom: none;
            margin-left: calc(var(--gutter) * 2);
            margin-right: calc(var(--gutter) * 2);

            &-Button {
                font-weight: bold;
                padding: 0;
                text-transform: uppercase;
            }

            &-SubMenu {
                background-color: transparent;
                padding: 0 26px;

                @include after-mobile {
                    background-color: white;
                }
            }
        }

        @include after-mobile {
            padding: 0;
        }
    }

    &-SubMenu {
        position: absolute;
        background-color: transparent;
        opacity: 0;
        transform: scaleY(0);
        transform-origin: top;
        width: 100%;
        transition: opacity $fast ease-in-out;

        &_isVisible {
            opacity: 1;
            transform: scaleY(1);
            position: static;
        }

        @include after-mobile {
            padding-bottom: 30px;
            background-color: white;
        }

        .Menu-SubMenu {
            background-color: var(--menu-sub2-bg);
            padding: 0 var(--gutter);
        }
    }

    &-MainCategories {
        background-color: var(--menu-bg-mobile);

        .indiana-scroll-container {
            height: 100%;
        }

        .Menu {
            &-Link {
                text-decoration: none;

                .Menu-ItemCaption {
                    position: relative;

                    @include after-mobile {
                        transition: border-bottom $rapid;
                        padding: 0;
                        margin: 0;
                        white-space: nowrap;
                    }
                }

                &:hover,
                &:focus {
                    outline: none;

                    .Menu-ItemCaption {
                        position: relative;
                    }
                }
            }
        }

        @include after-mobile {
            background-color: var(--menu-bg);
            height: var(--header-menuwrapper-height);
            max-width: var(--container-max);
            margin: 0 auto;
            padding-left: var(--gutter);
            padding-right: var(--gutter);
        }
    }

    &-SubMenuWrapper {
        @include after-mobile {
            max-width: var(--container-max);
            margin: auto;
            padding: calc(var(--gutter)) var(--gutter);
        }
    }

    &-SubMenuContent {
        @include after-mobile {
            display: grid;
            grid-template-columns: 3fr 2fr;
            grid-gap: 40px;
        }
    }

    &-SubTitle {
        .Menu-ItemFigure {
            padding: 30px 0 10px;
            display: inline-flex;
        }

        .Menu-ItemCaption {
            @include font(22, 30);
            text-transform: none;
        }
    }

    &-Promotion {
        display: none;
    }

    &-SubLevelDesktop {
        .Menu {
            &-ItemList {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                border: none;
                padding-top: 0;
                grid-gap: 15px;
                gap: 15px;

                .Menu-Link {
                    .Menu-ItemCaption {
                        @include after-mobile {
                            border-bottom: 1px solid transparent;
                        }
                    }
                }

                .Menu-Link:hover {
                    .Menu-ItemCaption {
                        position: relative;
                        @include after-mobile {
                            font-weight: 400;
                            border-bottom: 1px solid black;
                        }
                    }
                }
            }

            &-ItemFigure {
                padding: 0;
            }

            &-ItemCaption {
                @include font(16, 22, normal);
                color: var(--menu-link-color);
            }
        }
    }

    &-SubLevelMobile {
        .Menu {
            &-ItemList {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                border: none;
                padding-top: 0;

                .Menu-Link {
                    .Menu-ItemCaption {
                        padding: 15px 0 0 10px;
                    }

                    &:last-of-type {
                        .Menu-ItemCaption {
                            padding-bottom: 7px;
                        }
                    }
                }
            }

            &-ItemFigure {
                padding: 0;
            }

            &-ItemCaption {
                @include font(14, 22, normal);
                color: var(--menu-link-color);
            }
        }
    }

    &-CmsBlock {
        .CmsBlock-Wrapper {
            padding: 0;

            img {
                object-fit: contain;
                height: auto;
            }
        }

        .SliderWidget {
            max-height: var(--subcategory-height);

            .SliderWidget-Figure_Position_0,
            .SliderWidget-Figure_Position_1,
            .SliderWidget-Figure_Position_2 {
                height: 100%;
                display: none;

                @include desktop-big {
                    display: block;
                }

                .Image {
                    max-width: 60%;
                    height: var(--subcategory-height);

                    img {
                        max-height: calc(100% - 20px);
                    }
                }

                .SliderWidget-Figcaption {
                    --sliderwidget-text-color: $text;

                    width: calc(40% + 15px);
                    top: auto;
                    bottom: 0;
                    background-color: var(--sliderwidget-lightpink);
                }

                h2.SliderWidget-Title {
                    @include font(38, 44);
                }

                .SliderWidget-Link-Block {
                    display: none;
                }
            }
        }
    }
}
