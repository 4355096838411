// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

.RenderWhenVisible {
    &-Detector {
        height: 1px;
    }

    &-LineBreak {
        margin-bottom: 30px;
    }
}
