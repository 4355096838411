* {
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
}

a {
    text-decoration: none;
}

button {
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
}

fieldset {
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    margin-left: 20px;
}

img,
svg {
    max-width: 100%;
    vertical-align: bottom;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

select::-ms-expand {
    display: none;
}

input[type=number] {
    -moz-appearance: textfield;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input:-moz-ui-invalid {
    box-shadow: none;
}

table {
    border-spacing: 0;

    th,
    td {
        font-weight: 300;
        text-align: left;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}
