// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

.NoMatch {
    margin: 30px 0;

    &-Button {
        margin-top: 15px;
    }
}
