// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    --loader-diameter: 70px;
    --loader-spinner: 2px;
    --loader-spinner-color: #{$black};
    --loader-color: #{$black};
}

.Loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-top: calc(0px - var(--promotionbar-height));
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .6);
    z-index: z(loader);
    opacity: 0;
    animation: appear 1ms $shoot forwards;

    &_isFixed {
        position: fixed;
    }

    &_isBlock {
        position: static;
        background-color: transparent;
    }

    &_isFull {
        background-color: rgba(255, 255, 255, 1);
    }

    &_isBoxed {
        margin-top: 0;
    }

    &-Preloader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: var(--loader-diameter);
        height: var(--loader-diameter);

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: calc(var(--loader-spinner) / 2);
            top: calc(var(--loader-spinner) / 2);
            border: calc(var(--loader-spinner) / 2) solid var(--loader-spinner-color);
            border-top: calc(var(--loader-spinner) / 2) solid transparent;
            border-radius: 100%;
            animation: rotate 1s linear infinite;
        }

        svg {
            transform: scale(0.9);
            animation: appear 1s ease-in-out infinite alternate;
        }
    }
}
