// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    --productcard-background-color: #{$white};
    --productcard-color: #{$text-gray};
    --productcard-brand-bg: #{$background};
    --productcard-bagde-color: #{$border};
    --productcard-crumb-bg: #{$border};
    --productcard-crumb-bg-active: #{$black};
}

.ProductCard {
    @extend .DisableListType;
    position: relative;
    background-color: var(--productcard-background-color);
    text-align: center;
    border-radius: 4px;

    &-Box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        min-height: 330px;

        @include after-mobile {
            min-height: 425px;
        }
    }

    &-PriceWrapper {
        padding: 0 10px;
        display: grid;

        .ProductPrice {
            text-align: center;
            justify-self: center;

            @include font(12, 12);

            del {
                @include font(10, 20);
                font-weight: normal;
            }

            @include after-mobile {
                @include font(14, 12);

                del {
                    @include font(10, 20);
                    font-weight: normal;
                }
            }
        }
    }

    &-DeliveryPrice {
        color: #888;
        font-size: 10px;

        a {
            text-underline-offset: 5px;
            text-decoration-color: #bebcba;

            &:hover {
                text-decoration: underline;
                text-decoration-color: #bebcba;
            }
        }
    }

    &-EcoPart {
        color: #888;
        font-size: 10px;
    }

    &-WishlistButton {
        position: absolute;
        top: 12px;
        right: 12px;
        z-index: z(default);

        &_isActive {
            .svg-color {
                fill: var(--header-wishlist-active-color);
                stroke: var(--header-wishlist-active-color);
            }
        }
    }

    &-Figure {
        position: relative;
        margin: 0;
        overflow: hidden;
        padding: 5px;
        padding-top: 20px;
    }

    &-Slider {
        height: var(--slider-height);
        min-height: 77px;
    }

    &-PictureWrapper,
    &-PictureHover {
        position: relative;
        padding-bottom: var(--productcard-ratio);
        background-color: var(--productcard-background-color);
        height: 0;
    }

    &-PictureHover {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity $fast;

        &:first-child {
            opacity: 1;
        }

        &:last-child {
            opacity: 0;
        }

        &:hover,
        &:focus {
            &:first-child {
                opacity: 0;
            }

            &:last-child {
                opacity: 1;
            }
        }
    }

    &-PictureSingle {
        position: relative;
    }

    &-Picture,
    &-PictureHoverWrapper {
        position: relative;
        padding-bottom: var(--productcard-ratio);
    }

    &-PictureHoverWrapper_singleImage {
        .ProductCard-PictureHover {
            &:first-child {
                opacity: 1;
            }

            &:last-child {
                opacity: 0;
            }

            &:hover,
            &:focus {
                &:first-child {
                    opacity: 1;
                }

                &:last-child {
                    opacity: 0;
                }
            }
        }
    }

    &-Crumbs {
        position: relative;
    }

    &-Crumb {
        width: 7px;
        height: 7px;
        margin-right: 7px;
        background-color: var(--productcard-crumb-bg);
        border-radius: 100%;
        font-size: 0;

        &:last-child {
            margin-right: 0;
        }

        &_isActive {
            background-color: var(--productcard-crumb-bg-active);
        }
    }

    &-Link {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        height: 100%;
    }

    &-ContentWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-grow: 1;
        gap: 25px;
        margin: 12px 0 28px 0;
    }

    &-Content {
        text-align: center;
        padding: 0 10px;
        margin-bottom: -6px;
        @include after-mobile {
            padding: 0 20px;
        }
    }

    &-Brand {
        @include font(10, 14);
        position: absolute;
        top: 8px;
        left: 8px;
        padding: 3px 6px;
        text-transform: uppercase;
        background-color: var(--productcard-brand-bg);
    }

    &-NameWrapper {
        display: flex;
        flex-direction: column;
    }

    &-Name {
        @include font(16, 26, 600);
        font-weight: bold;
        max-height: 26px;
        overflow: hidden;
        margin-bottom: 5px;

        @include after-mobile {
            @include font(18, 28);
        }
    }

    &-Name2,
    &-Name3 {
        text-transform: capitalize;
        @include font(12, 16);
        margin-top: 0;
        overflow: hidden;
        color: var(--productcard-color);

        @include after-mobile {
            @include font(14, 22);
            max-width: 200px;
        }

        ~ .ProductCard-PriceBadge,
        ~ .ProductCard-Price {
            margin-top: auto;
        }
    }

    &-PriceBadge {
        @include font(10, 10, normal, var(--productcard-bagde-color));

        ~ .ProductPrice {
            margin-top: 0;
        }
    }

    &-Price {
        font-weight: bold;
    }

    &-ConfigurableOptions {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        &_hasRest {
            margin-left: 19px;
        }

        @include after-mobile {
            min-height: 18px;
        }
    }

    &-Stock {
        @include font(10, 22);
        display: flex;
        align-items: center;
        justify-content: center;

        @include after-mobile {
            @include font(14, 22);
            justify-content: flex-end;
        }

        i {
            margin-right: 5px;
            font-style: normal;
        }
    }

    &-StockWrapper {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    &-StockCircle {
        display: block;
        width: 8px;
        height: 8px;
        background-color: $green;
        border-radius: 100%;

        &_inStock {
            &_OUT_OF_STOCK {
                background-color: $red;
            }

            &_ALMOST_OUT_OF_STOCK {
                background-color: $yellow_dark;
            }
        }
    }

    &-ColorDiv {
        position: relative;
        border-radius: 99px;
        overflow: hidden;
        width: 18px;
        height: 18px;

        &_rest {
            margin-left: -3px;
            margin-top: 1px;
            @include font(16, 16, 600);
            color: #{$text-gray};
        }

        &_active {
            border: 1px solid black;
            width: 24px;
            height: 24px;

            img {
                width: inherit;
                height: inherit;
            }

            &::after {
                content: '';
                width: calc(100% - 4px);
                height: calc(100% - 4px);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 2px solid white;
                border-radius: 100%;
            }
        }
    }

    &-Color {
        width: 19px;
        height: 19px;
        margin-right: 10px;
        position: relative;
        display: flex;

        &:last-child {
            margin-right: 0;
        }

        &_active {
            width: 24px;
            height: 24px;

            img {
                width: inherit;
                height: inherit;
            }

            &::after {
                content: '';
                width: calc(100% - 5px);
                height: calc(100% - 5px);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 1px solid #fff;
                border-radius: 100%;
            }
        }

        &_extraColors {
            position: relative;
            border: 1px solid var(--productcard-crumb-bg);

            &::before,
            &::after {
                content: '';
                position: absolute;
                background-color: var(--productcard-crumb-bg);
            }

            &::before {
                width: 9px;
                height: 1px;
                right: 3px;
                top: 8px;
            }

            &::after {
                width: 1px;
                height: 9px;
                right: 7px;
                top: 4px;
            }
        }
    }

    button.ProductCard-AddToCart {
        font-size: clamp(9px, 2.5vw, 12px);
        display: flex;
        align-items: center;
        min-width: auto;
        padding: 8px 20px;
        margin: 0 auto 28px;
        max-width: 90%;
        white-space: nowrap;

        svg {
            margin-right: 10px;
        }
    }

    &-CmsProduct {
        min-height: 250px;
        position: relative;
        background-color: var(--productcard-background-color);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        text-align: center;
        list-style: none;
        margin: 0;

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: z(default);
        }

        .ProductCard {
            &-Name {
                color: $white;
                text-align: center;
            }

            &-Box {
                width: 100%;
                position: absolute;
                left: 0;
                top: 50%;
                padding: 30px 25px 40px;
                transform: translateY(-50%);
                z-index: z(product-cms-box);
                text-transform: uppercase;
                color: $white;
                text-align: center;

                p {
                    margin-top: 5px;
                }

                a {
                    margin: 20px auto 0;
                }
            }
        }

        a,
        .Button {
            --button-default-bg-color: #{$white};
            --button-default-color: #{$black};

            @include font(14, 20, normal);
            display: table;
            position: relative;
            outline: none;
            padding: 10px 20px;
            margin-top: 20px;
            border-radius: 0;
            border: 1px solid var(--button-default-bg-color);
            transition: all $rapid;
            text-decoration: none;
            text-transform: uppercase;
            background-color: transparent;
            color: var(--button-default-bg-color);

            &:hover,
            &:focus,
            &:active {
                background-color: var(--button-default-bg-color);
                color: var(--button-default-color);
            }
        }
    }

    .TextPlaceholder,
    .Image_isPlaceholder {
        opacity: 0;
        animation: appear 100ms 300ms forwards;
    }

    &-AddToCart-QtyIncWarning {
        margin: auto auto 25px auto;
        padding: 5px 15px;
        width: 250px;
        background-color: #f8e8c4;
        font-size: 12px;
        font-weight: bold;
        display: flex;

        @include before-desktop {
            width: 90%;
        }

        .Note {
            width: 40px;
            margin: 0 10px 0 -5px;
        }
    }
}

li.ProductCard:not(a.ProductCard-Link) {
    overflow: visible;
    @include after-mobile {
        transition: all ease-in-out .2s;

        &:hover {
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.05);
        }
    }
}
