// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

.NotificationList {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: z(notification);

    @include after-mobile {
        width: auto;
        top: 20px;
        right: 20px;
        left: auto;
    }
}
