// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

.CmsPageBlock {
    &-Loader {
        --header-usp-height: 40px;
        --header-nav-height: 56px;
        --header-height:
            calc(
                var(--header-nav-height)
            );
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - var(--header-height) - 10px);
        max-width: 100vw;
        width: 1000px;

        .Loader {
            margin-bottom: calc(var(--header-height) + 20px);
        }
    }
}
