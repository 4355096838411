html {
    font-size: 100%;
}

* {
    @extend .DefaultFont;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    @include font(16, 24, false, $text);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 15px;
}

h1 {
    @include font(28, 36, 700, $text);
    @extend .TitleFont;
    margin-bottom: 20px;

    @include after-mobile {
        @include font(38, 46, 700, $text);
    }
}

h2 {
    @include font(28, 36, 700, $text);
    @extend .TitleFont;
    margin-bottom: 20px;
}

h3 {
    @include font(20, 28, bold, $text);
    margin-bottom: 20px;
}

h4 {
    @include font(18, 26, bold, $text);
    margin-bottom: 20px;
}

h5 {
    @include font(16, 24, bold, $text);
    margin-bottom: 20px;
}

h6 {
    @include font(14, 22, bold, $text);
    margin-bottom: 20px;
}

p {
    @include font(16, 24);
    margin: 0;

    & + & {
        margin-top: 15px;
    }
}

a {
    color: inherit;
    transition: all $rapid;
    text-decoration: underline;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}
