// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

.ProductAttributeValue {
    text-decoration: none;

    label {
        align-self: center;
    }

    label:first-of-type ~ label {
        min-width: auto;
        top: auto;
        align-self: center;
    }

    &-Text {
        text-transform: none;
        padding: 0 32px;

        label {
            &:first-of-type {
                .input-control {
                    left: 2rem;
                    min-width: initial;
                }
            }
        }
    }

    &-Image,
    &-Color,
    &-Image-Overlay {
        border-radius: unset;
        position: relative;
        min-height: 33px;
        width: 33px;
        box-shadow: unset;
        margin: 0;

        &::before,
        &::after {
            content: unset;
        }
    }
}
