// Default Grid
:root {
    --container-max: 1440px;
    --container-big: 1400px;
    --container-small: 1000px;
    --container-min: 600px;
    --gutter: 20px;
    --gutter-sm: 15px;
}

@media only screen and (max-width: $mobile) {
    :root {
        --gutter: 20px;
        --gutter-sm: 8px;
    }
}
