// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    --footer-social-title-coler: #{$text-gray};
    --footer-link-color: #{$text-gray};
    --footer-link-hover-color: #{$text};
    --footer-cards-bg: #{$white};
    /* stylelint-disable-next-line length-zero-no-unit */
    --footer-space-bottom: 0px;
    --footer-border-color: #{$border3};
}

.Footer {
    padding-bottom: var(--footer-space-bottom);
    background-color: transparent;

    @include after-mobile {
        margin-top: 80px;
        background-color: white;
    }

    &-Bottom {
        margin: 0 auto;
        max-width: var(--container-max);

        .ExtraMenu-Container {
            display: flex;
            justify-content: center;
            background-color: transparent;

            a {
                padding: 0;
            }

            li {
                padding: 0;
                text-decoration: none;
                border-bottom: none !important;
            }
        }

        &-Container {
            border-top: 1px solid var(--menu-border-color);
            padding: 28px 0 50px 0;
            margin: 0 var(--gutter);
        }
    }

    &_name {
        &_cart {
            --footer-space-bottom: 148px; // CartPage-Summary

            @include after-mobile {
                /* stylelint-disable-next-line length-zero-no-unit */
                --footer-space-bottom: 0px;
            }
        }

        &_pdp {
            --footer-space-bottom: 92px; // ProductActions-AddToCartWrapper

            @include after-mobile {
                /* stylelint-disable-next-line length-zero-no-unit */
                --footer-space-bottom: 0px;
            }
        }

        &_checkout {
            --footer-space-bottom: 136px; // Checkout-StickyButtonWrapper

            @include after-mobile {
                /* stylelint-disable-next-line length-zero-no-unit */
                --footer-space-bottom: 0px;
            }
        }
    }

    &-Social,
    &-Cards {
        width: 100%;
        padding: 20px var(--gutter);
        @include before-desktop {
            padding: 40px var(--gutter);
        }

        .CmsBlock-Wrapper,
        .CmsBlock-Wrapper p {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            color: $text-gray;

            a {
                text-decoration: none;
            }

            p + p {
                margin-top: 5px;
            }

            @include after-mobile {
                a img {
                    display: inline-block;
                    margin: 0 10px 10px;
                }

                a {
                    display: inline-block;

                    &:hover {
                        color: $text;
                        text-decoration: underline;
                    }
                }
            }

            img,
            source {
                max-height: 41px;
                width: auto;
            }
        }

        .Image {
            display: flex;
            align-items: center;
            width: auto;
            height: 100%;
            position: relative;
            padding-bottom: 0;

            &:last-child {
                margin-right: 0;
            }

            &-Image {
                position: static;
            }
        }
    }

    &-Social {
        @include after-mobile {
            padding: 0;
        }

        .CmsBlock-Wrapper p {
            width: 100%;
        }
    }

    .footer-social {
        margin-bottom: 20px;
    }

    &-Cards {
        padding: 20px var(--gutter);
        margin-top: 60px;
        background-color: var(--footer-cards-bg);

        @include after-mobile {
            margin-top: 25px 50px;
        }

        .CmsBlock-Wrapper {
            justify-content: center;

            img:not([data-webpconverted]) {
                margin: 0 20px;

                &:first-child {
                    margin: 0;
                }
            }
        }
    }

    &-Social-Title,
    &-Payment-Title {
        @include font(14, 22);
        margin-bottom: 20px;
        color: var(--footer-social-title-coler);
        text-align: center;

        @include after-mobile {
            @include font(16, 24, bold, $text);
            text-align: left;
        }
    }

    &-Menu-List {
        @include container;

        margin-bottom: 20px;

        @include after-mobile {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: var(--gutter);
            padding-top: 25px;
        }

        @include tablet-landscape {
            grid-template-columns: repeat(4, 1fr);
        }

        .Footer-Social {
            display: none;

            @include after-mobile {
                display: block;
            }
        }
    }

    &-Menu {
        margin: 15px 0;
        text-align: center;

        @include after-mobile {
            text-align: left;
            margin: 0;
        }

        svg {
            margin-top: -10px;
            margin-bottom: 10px;
            transition: transform $fast;
            will-change: transform;

            @include after-mobile {
                display: none;
            }
        }

        input {
            display: none;

            &:checked ~ svg {
                transform: scale(-1);
            }

            &:checked ~ ul {
                height: auto;
                opacity: 1;
                transform: scaleY(1);
            }
        }
    }

    &-Menu-Title {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: bold;

        @include after-mobile {
            pointer-events: none;
            align-items: flex-start;
            margin-bottom: 10px;
        }
    }

    &-Menu-ItemList {
        height: 0;
        opacity: 0;
        transform: scaleY(0);
        transition: transform $normal;
        transform-origin: top;
        will-change: transform;

        @include after-mobile {
            height: auto;
            opacity: 1;
            transform: scaleY(1);
        }
    }

    &-Menu-Item {
        @extend .DisableListType;

        @include after-mobile {
            a {
                display: inline-block;
                color: var(--footer-link-color);

                &:hover {
                    color: var(--footer-link-hover-color);
                    text-decoration: underline;
                }
            }
        }
    }

    &-Menu-Link {
        display: block;
        padding: 5px 0;
        text-decoration: none;

        &-Cookie:hover {

            text-decoration: underline;
        }
    }

    .StoreSwitcher {
        background-color: transparent;
        border: none;
        position: absolute;
        margin: 0;

        &::after,
        &::before {
            display: none;
        }
    }

    &-PowerStep {
        padding: var(--gutter);

        .wrapper {
            background: white;
            margin: calc(var(--gutter) * -1);
            margin-top: calc((var(--gutter) * 2 * -1) - 4px);
            margin-bottom: var(--gutter);
            padding: var(--gutter);
            padding-top: calc(var(--gutter) * 2);
        }

        &-PriceNameWrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;

            @include after-mobile {
                max-width: 300px;
            }
        }

        &-Name {
            @include font(16, 16, 600);
            margin: 0;
        }

        .ProductPrice.ProductActions-Price {
            @include font(16, 16, bold);
            margin: 0;
        }

        &-Name2 {
            @include font(13, 16, normal, $text-gray);
            margin: 0;
            text-align: left;

            @include after-mobile {
                max-width: 300px;
            }
        }

        &-Product {
            display: flex;
            align-items: center;
            flex-direction: column;
            position: relative;
            margin-bottom: 10px;
            text-align: center;
            width: 100%;
        }

        &-tos {
            max-width: 300px;
            margin: auto;
            padding-bottom: 30px;
            @include font(14,20,200);

            .Title {
                @include font(16,16,bold);
                text-align: center;
                padding: 10px;
            }

            li {
                padding-bottom: 10px;
            }
        }

        &-Stock-Warning {
            margin: auto auto 25px auto;
            padding: 8px 15px;
            width: 300px;
            background-color: #f8e8c4;
            font-size: 14px;
            font-weight: bold;
            display: flex;
            line-height: normal;

            .Note {
                width: 40px;
                margin: 0 10px 0 -5px;
            }
        }

        &-Title {
            margin: auto;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
            font-family: 'Open Sans', sans-serif;
            @include font(25, 32, normal, $text);

            svg {
                margin-bottom: 10px;
            }
        }

        &-Sub {
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            margin: auto;
            width: 100%;

            &-Subtotal {
                @include font(12, 12, 600);
            }

            .ProductPrice.ProductActions-Price {
                @include font(12, 12, 600);
            }

            @include after-mobile {
                max-width: 300px;
            }
        }

        &-Button {
            text-align: center;
            margin: auto;
            margin-bottom: 30px;

            @include after-mobile {
                max-width: 300px;
            }
        }

        &-Link {
            text-align: center;
            text-decoration: underline;
            margin-bottom: 10px;
            position: relative;
            @include font(16, 16, bold);

            cursor: pointer;
        }

        &_relatedProducts {
            background: $background;
        }

        &-Content {
            font-size: 12px;
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 10px;
            margin-bottom: 20px;

            @include after-mobile {
                max-width: 300px;
            }
        }

        .ProductPrice {
            font-size: 12px;
        }

        &-Image {
            width: 100%;
            height: 120px;
            position: relative;
        }
    }
}

.PowerStep {
    &-Popup {
        display: flex;
        align-items: flex-end;
    }
}

.PowerStep-Popup .Popup-Content_small {
    max-height: 90%;
    background: white;

    &_relatedProducts {
        background: $background;
    }
}

.PowerStep-Popup_isVisible {
    .Popup-Content_small {
        box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.2);
        max-height: 90%;
        overflow-x: hidden;
        overflow-y: auto;
        margin: 0 auto;
        border-radius: 4px 4px 0 0;
        padding: 0;

        .Popup-Header {
            background: white;
        }

        @include after-mobile {
            min-width: 500px;
            max-width: 900px;
            overflow-y: auto;
            overflow-x: hidden;
            margin: 0 auto;
            border-radius: 4px;
            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
        }
    }

    .ProductLinks {
        &-Wrapper {
            margin-left: calc(var(--gutter) * -1);
            margin-right: calc(var(--gutter) * -1);
            padding: 0;

            @include after-mobile {
                margin-left: 0;
                margin-right: 0;
                padding: 0 var(--gutter);

                &-Links {
                    grid-template-columns: repeat(4, minmax(180px, 1fr));
                }
            }
        }
    }
}
