// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    --productcard-background-color: #{$white};
    --productcard-color: #{$text-gray};
    --productcard-brand-bg: #{$background};
    --productcard-bagde-color: #{$border};
    --productcard-crumb-bg: #{$border};
    --productcard-crumb-bg-active: #{$black};
}

.ProductCardList {
    @extend .DisableListType;
    position: relative;

    display: flex;
    align-items: center;
    padding: 15px 0;
    margin: 0;
    max-height: 108px;
    background-color: var(--cartpage-bg);
    margin-bottom: 15px;
    border-radius: 12px;

    @include after-mobile {
        margin-bottom: 0;
        border-radius: 0;

        &:not(:first-of-type) {
            &::after {
                content: '';
                background: var(--cartitem-first-child-border-color);
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 1px;
                width: 95%;
            }
        }
    }

    &-Wrapper {
        margin: 0;
        width: 100%;
    }

    &-Box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &-PriceWrapper {
        .ProductPrice {

            margin-top: 5px;
            @include font(11, 14, bold, var(--productcard-color));

            @include after-mobile {
                display: block;
                margin-top: 0;
                @include font(12, 14, bold, var(--productcard-color));
                justify-content: center;

                del {
                    font-weight: normal;
                    margin-right: 1rem;
                }
            }
        }
    }

    &-WishlistButton {
        position: absolute;
        top: 12px;
        right: 12px;
        z-index: z(default);

        &_isActive {
            .svg-color {
                fill: var(--header-wishlist-active-color);
                stroke: var(--header-wishlist-active-color);
            }
        }
    }

    &-Figure {
        position: relative;
        margin: 0;
        overflow: hidden;
        padding: 0;
    }

    &-Slider {
        height: var(--slider-height);
        min-height: 77px;
    }

    &-PictureWrapper,
    &-PictureHover {
        position: relative;
        padding-bottom: var(--productcard-ratio);
        background-color: var(--productcard-background-color);
        height: 0;
    }

    &-PictureHover {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity $fast;

        &:first-child {
            opacity: 1;
        }

        &:last-child {
            opacity: 0;
        }

        &:hover,
        &:focus {
            &:first-child {
                opacity: 0;
            }

            &:last-child {
                opacity: 1;
            }
        }
    }

    &-Picture,
    &-PictureHoverWrapper {
        position: relative;
        padding-bottom: var(--productcard-ratio);

        min-width: 84px;
        @include after-mobile {
            min-width: 105px;
        }
    }

    &-PictureHoverWrapper_singleImage {
        .ProductCardList-PictureHover {
            &:first-child {
                opacity: 1;
            }

            &:last-child {
                opacity: 1;
            }

            &:hover,
            &:focus {
                &:first-child {
                    opacity: 1;
                }

                &:last-child {
                    opacity: 1;
                }
            }
        }
    }

    &-Crumbs {
        position: relative;
    }

    &-Crumb {
        width: 7px;
        height: 7px;
        margin-right: 7px;
        background-color: var(--productcard-crumb-bg);
        border-radius: 100%;
        font-size: 0;

        &:last-child {
            margin-right: 0;
        }

        &_isActive {
            background-color: var(--productcard-crumb-bg-active);
        }
    }

    &-Link {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        height: 108px;
        width: 108px;
    }

    &-Content {
        padding: 0 15px;

        @include after-mobile {
            padding: 0 20px;
        }
    }

    &-Data {
        align-items: center;
        flex-direction: row;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-right: 20px;
    }

    &-Brand {
        @include font(10, 14);
        position: absolute;
        top: 8px;
        left: 8px;
        padding: 3px 6px;
        text-transform: uppercase;
        background-color: var(--productcard-brand-bg);
    }

    &-MainDetails {

        height: auto;
        max-width: calc(50% - var(--gutter));

        @include after-mobile {
            width: calc(100% - 108px - 200px);
            min-width: 200px;
        }
    }

    &-Name {
        @include font(18, 26, 600);
        max-height: 26px;
        overflow: hidden;

        @include after-mobile {
            @include font(20, 28);
        }
    }

    &-Name2,
    &-Name3,
    &-SofaName {
        text-transform: capitalize;
        @include font(12, 20);
        margin-top: 0;
        overflow: hidden;
        color: var(--productcard-color);
        @include after-mobile {
            @include font(16, 22);
            max-width: 200px;
            margin: 0 auto 5px;
        }

        ~ .ProductCard-PriceBadge,
        ~ .ProductCard-Price {
            margin-top: auto;
        }
    }

    &-SofaName {
        max-width: unset;
        @include font(14, 18);
    }

    &-Name3 {
        @include after-mobile {
            margin: 0 auto 25px;
        }
    }

    &-PriceBadge {
        @include font(10, 10, normal, var(--productcard-bagde-color));

        ~ .ProductPrice {
            margin-top: 0;
        }
    }

    &-Price {
        font-weight: 600;
    }

    &-ConfigurableOptions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 15px 0;
        @include after-mobile {
            min-height: 18px;
        }
    }

    &-Color {
        width: 18px;
        height: 18px;
        object-fit: cover;
        object-position: center;
        border-radius: 100%;
        overflow: hidden;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }

        &_extraColors {
            position: relative;
            border: 1px solid var(--productcard-crumb-bg);

            &::before,
            &::after {
                content: '';
                position: absolute;
                background-color: var(--productcard-crumb-bg);
            }

            &::before {
                width: 9px;
                height: 1px;
                right: 3px;
                top: 8px;
            }

            &::after {
                width: 1px;
                height: 9px;
                right: 7px;
                top: 4px;
            }
        }
    }

    button.ProductCardList-AddToCart {
        min-height: 36px;
        padding: 0 25px;
        border-radius: 5px;
        font-size: 13px;
        min-width: fit-content;
        width: fit-content;

        @include mobile {
            width: 50%;
            padding: 0 5px !important;
            min-width: unset;
        }
    }

    &-CmsProduct {
        min-height: 250px;
        position: relative;
        background-color: var(--productcard-background-color);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        text-align: center;
        list-style: none;
        margin: 0;

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: z(default);
        }

        .ProductCard {
            &-Name {
                color: $white;
                text-align: center;
            }

            &-Box {
                width: 100%;
                position: absolute;
                left: 0;
                top: 50%;
                padding: 30px 25px 40px;
                transform: translateY(-50%);
                z-index: z(product-cms-box);
                text-transform: uppercase;
                color: $white;
                text-align: center;

                p {
                    margin-top: 5px;
                }

                a {
                    margin: 20px auto 0;
                }
            }
        }

        a,
        .Button {
            @include font(14, 20, normal);
            display: table;
            position: relative;
            outline: none;
            padding: 10px 20px;
            margin-top: 20px;
            border-radius: 0;
            border: 1px solid var(--button-default-bg-color);
            transition: all $rapid;
            text-decoration: none;
            text-transform: uppercase;
            background-color: transparent;
            color: var(--button-default-bg-color);

            &:hover,
            &:focus,
            &:active {
                background-color: var(--button-default-bg-color);
                color: var(--button-default-color);
            }
        }
    }

    .TextPlaceholder,
    .Image_isPlaceholder {
        opacity: 0;
        animation: appear 100ms 300ms forwards;
    }
}
