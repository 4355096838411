// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

.StoreItems {
    &-Item {
        @include font(18, 26);
        display: block;
        position: relative;
        margin: 15px auto;
    }
}
