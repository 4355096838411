/* following https://webaim.org/techniques/css/invisiblecontent/ */
.screen-reader-only {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}
