// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    /* stylelint-disable-next-line length-zero-no-unit */
    --demo-notice-height: 0px;
    --demo-notice-color: #{$yellow};
    --demo-notice-top:
        calc(
            env(safe-area-inset-top)
        );
}

.DemoNotice {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--demo-notice-height);
    position: fixed;
    left: 0;
    top: var(--demo-notice-top);
    z-index: z(header);
    background: var(--demo-notice-color);

    @at-root .isDemoVisible {
        --demo-notice-height: 40px;
    }
}
