// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

.Breadcrumbs {
    display: none;

    @include after-mobile {
        display: block;
        padding: 10px 0;
    }
}
