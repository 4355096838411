// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

:root {
    --newsletter-border: #{$gray2};
}

.Newsletter {

    max-width: var(--container-small);
    margin: 0 auto;
    padding: 20px var(--gutter);
    background-color: $greyBlue;

    @include after-mobile {
        display: grid;
        justify-content: center;
        grid-gap: 30px;
        grid-template-columns: repeat(2, 1fr);
        max-width: var(--container-big);
        padding: 80px var(--gutter) 80px;
    }

    &-Block {
        > div {
            max-width: 350px;
            margin-left: auto;
            margin-right: auto;
            text-align: left;
            @include after-mobile {
                max-width: 400px;
                margin-right: 0;
            }
        }
    }

    &-Form {
        > form {
            max-width: 350px;

            margin-right: auto;
            margin-left: auto;
            @include after-mobile {
                max-width: 400px;
                margin-left: 0;
                margin-right: auto;
            }
        }

        .CmsBlock-Wrapper.newsletter-signup-form {
            .clickdform.mainDiv {
                float: initial;
                margin-top: 1.2rem;
                overflow: initial;
                padding: 0 !important;

                form#clickdimensionsForm {
                    max-width: 400px;
                    @include mobile {
                        margin: auto;
                    }

                    > .responsiveRow {
                        display: flex;
                        flex-direction: column-reverse;
                        text-align: left;

                        > .responsiveCell:not(.emptyCell) {
                            margin-bottom: 1.2rem;
                            position: relative;
                            width: 100%;

                            // Label Container
                            > div:not(.buttonContainer):nth-of-type(1) {
                                left: 14px;
                                //pointer-events: none;
                                position: absolute;
                                top: 7px;
                                @include mobile {
                                    left: 50%;
                                    text-align: center;
                                    transform: translateX(-50%);
                                }

                                > span {
                                    color: #9ea3a6 !important;
                                    font-family: 'Open Sans', sans-serif !important;
                                    font-size: 0.75rem !important;
                                    font-weight: 400 !important;
                                    line-height: 1.5rem;
                                    @include mobile {
                                        font-size: .75rem;
                                    }
                                }
                            }

                            // Input Container
                            > div:nth-of-type(3) {
                                display: block;
                                width: 100%;

                                input {
                                    border: 1px solid var(--newsletter-border);
                                    font-family: 'Open Sans', sans-serif !important;
                                    font-size: 1rem !important;
                                    line-height: 1.15;
                                    max-width: initial;
                                    outline: none;
                                    padding: 28px 12px 8px;
                                    width: 100%;
                                }
                            }
                        }

                        > .responsiveCell {
                            text-align: left !important;

                            .buttonContainer {
                                @include mobile {
                                    width: 100%;
                                    margin-right: 0 !important;
                                }

                                input#btnSubmit {
                                    background-color: var(--button-default-bg-color);
                                    border-radius: 4px;
                                    border: 1px solid var(--button-default-bg-color);
                                    color: var(--button-default-color) !important;
                                    font-family: 'Open Sans', sans-serif !important;
                                    font-size: 1rem !important;
                                    font-weight: 700;
                                    line-height: 1.25rem;
                                    margin: 0;
                                    min-width: 225px;
                                    padding: 10px 60px;
                                    @include mobile {
                                        padding: 15px 25px;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }

                    /* for checkbox */

                    /*
                    > .responsiveRow:nth-of-type(5) {
                        > .responsiveCell:not(.emptyCell) {

                            padding: 10px 0;
                            text-align: left !important;

                            > div {
                                width: 100%;
                                left: 0 !important;
                            }

                            input {
                                height: 25px;
                                width: 25px;
                                margin-right: 10px;
                            }

                            span {
                                font-family: 'Open Sans', sans-serif;
                            }
                        }
                    }
                    */
                }
            }
        }
    }

    .CmsBlock-Wrapper {
        h2 {
            margin-top: calc(2 * var(--gutter));
            font-weight: bold;
            font-size: clamp(32px, 2vw, 42px);
            line-height: 125%;
            font-family: 'Open Sans', sans-serif;
        }

        @include after-mobile {
            text-align: right;

            h2 {
                margin-top: calc(var(--gutter) / 2);
                margin-left: auto;
                max-width: 350px;
            }
        }
    }

    &-Title {
        margin-bottom: 5px;
    }

    &-PlaceholderContent {
        &::after {
            content: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            word-break: break-all;
        }
    }

    &-PlaceholderH2 {
        &::after {
            content: 'aaaaaaaaaaaaaaaaaaaaaaaaa';
            word-break: break-all;
            font-size: 42px;
            line-height: 52px;
        }
    }

    .Form {
        > div {
            color: black;
        }

        .Field {
            input {
                box-shadow: none;
                border: 1px solid var(--newsletter-border);
            }

            &_type_checkbox {
                margin-top: 20px;
            }

            &-Label {
                font-weight: 300;
                width: calc(100% - 25px);
                text-align: center;

                @include after-mobile {
                    text-align: left;
                    @include font(16, 24, 400, #9EA3A6);
                }
            }
        }
    }

    .Button {
        width: 100%;
        margin-top: 15px;
        font-weight: bold;

        @include after-mobile {
            width: auto;
            padding: 10px 60px;
        }
    }
}

.some {
    .CmsBlock-Wrapper.newsletter-signup-form .clickdform.mainDiv form#clickdimensionsForm {
        > .responsiveRow:nth-of-type(4) {
            > .responsiveCell:not(.emptyCell) {
                .buttonContainer {
                    margin-right: 0 !important;
                    margin-left: 0;
                    transform: translateX(-50%);
                    left: 50%;
                    position: relative;
                }
            }
        }

        /* for checkbox */

        /*
        > .responsiveRow:nth-of-type(5) {
            > .responsiveCell:not(.emptyCell) {
                display: flex;
                justify-content: center;

                > div {
                    left: unset !important;
                    width: fit-content;
                }
            }
        }
        */
    }
}
