// settings
@import '../../style/settings/color';
@import '../../style/tools/mixins';
@import '../../style/settings/variables';

// tools
@import '../../style/tools/breakpoints';

@import '../../style/utilities/deletethis';

.ProductBadges {
    &-BadgeGroup {
        position: absolute;
        display: flex;
        flex-direction: column;
        max-width: 50%;
        align-items: flex-start;
        z-index: 3;

        &_position {
            &_top-left {
                top: 8px;
                left: 8px;
            }

            &_top-right {
                top: 8px;
                left: 40px;
            }

            &_bottom-left {
                bottom: 8px;
                left: 8px;
            }

            &_bottom-right {
                bottom: 8px;
                right: 8px;
            }

            @include after-mobile {
                &_top-left {
                    top: 15px;
                    left: 15px;
                }

                &_top-right {
                    top: 15px;
                    left: 60px;
                }

                &_bottom-left {
                    bottom: 15px;
                    left: 15px;
                }

                &_bottom-right {
                    bottom: 15px;
                    right: 15px;
                }
            }
        }
    }

    &-Badge {
        text-transform: capitalize;
        margin-bottom: 4px;

        @include after-mobile {
            margin-bottom: 8px;
        }
    }

    &-BadgeContent {
        padding: 4px;
        @include font(8, 10, bold);

        @include after-mobile {
            padding: 8px;
            @include font(10, 12, bold);
        }
    }
}
